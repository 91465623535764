<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Collect lists') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <a class="button" style="min-width: 115px;" @click.prevent="CreateListShow" href="">{{ $t('Create') }}</a>
          </div>
          <div class="grid-search">
            <input type="text" ref="search" @keypress.enter="Search" v-model="search.query" :placeholder="$t('Search by list ID')">
          </div>
        </div>
        <table class="list table odd-even">
          <thead>
            <tr class="list__actions">
              <th class="id">
                <a :class="[page.order.by == 'id' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('id')" href="">
                  <span>{{ $t('List ID') }}</span>
                </a>
              </th>
              <th class="created-at">{{ $t('Created') }}</th>
              <th class="created-by">{{ $t('List name') }}</th>
              <th class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <tr :data-id="list.id" class="list__row" :key="list.id" v-for="list in lists">
              <td class="id">{{ list.id }}</td>
              <td class="created-at">{{ DateFormat(list.create_date) }}</td>
              <td class="created-by">{{ list.name }}</td>
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent href="" class="icon dots">
                  <ul>
                    <li>
                      <a @click.prevent="OpenPDF(list.id)" href="">{{ $t('Open PDF') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="DownloadPDF(list)" href="">{{ $t('Download') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="PrintPDF(list.id)" href="">{{ $t('Print') }}</a>
                    </li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="grid-pagination">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{ NumberFormat(page.current) }}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{ NumberFormat(page.last) }}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>
      </div>
      <div class="loading" v-if="loading">
        <div class="loading-element">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <Modal modal="create" :value="modal.create.open" :title="$t('New collect list')">
      <div id="create-list" class="create-list">
        <div class="search-form">
          <div class="form-elements">
            <div class="input query">
              <label>
                {{ $t('Search') }}
                <input type="text" name="query" class="input query" @keypress.enter="SearchOrders" />
              </label>
            </div>
            <div class="select company" style="width: 100%; display: flex; align-items: flex-end;">
              <label style="width: 200px; flex-shrink: 0;">
                {{ $t('Company') }}
                <v-select name="company" @input="SearchOrders" :value="modal.create.company.selected" v-model="modal.create.company.selected" :options="modal.create.company.options" :clearable="false" />
              </label>
              <div class="checkbox-list-label" style="width: 100%; margin-left: 30px;">
                {{ $t('Delivery company') }}
                <div class="input courier">
                  <ul class="checkbox-list" style="padding: 1px 10px;">
                    <li class="checkbox-item" :key="courier.code" v-for="courier in modal.create.couriers">
                      <label class="checkbox label" :for="courier.code" style="flex-direction: row; align-items: center;">
                        <div class="v-wrapper checkbox" style="min-height: 0px;">
                          <div class="v-checkbox-label" style="min-height: 0px;">
                            <input class="v-checkbox-radio" type="checkbox" :id="courier.code" :name="courier.code" @input="SearchOrders">
                            <span class="v-checkbox-radio-toggle" style="width: 18px; height: 18px;" />
                          </div>
                        </div>
                        <span :class="['agent-icon', courier.code]" :title="courier.label" style="margin: 0 0 0 6px;" />
                        <small class="count">{{ NumberFormat(modal.create.available[courier.code] || 0) }}</small>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="courier-actions" style="margin-left: 30px; flex-shrink: 0;">
                <button class="button green" style="min-width: 115px;" @click="AutoCreateListForEachCourier">{{ $t('Create all') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="search-result">
          <div class="list-wrapper">
            <div class="list-body">
              <div class="list-head">
                <ul class="list-label">
                  <li class="list-row">
                    <div class="list-col" style="display: flex; flex-direction: row; align-items: center;">
                      <div class="label">
                        <div class="v-wrapper checkbox" style="min-height: 0px;">
                          <label class="v-checkbox-label" style="min-height: 0px;">
                            <input class="v-checkbox" type="checkbox" name="list-toggle-all" :checked="modal.create.list.all" v-model="modal.create.list.all" @change="ToggleAllOrders">
                            <span class="v-checkbox-toggle" style="width: 18px; height: 18px;" />
                          </label>
                        </div>
                      </div>
                      <span style="margin-left: 10px;">{{ $t('Order number') }}</span>
                    </div>
                    <div class="list-col">{{ $t('Company') }}</div>
                    <div class="list-col">{{ $t('Shipping method') }}</div>
                  </li>
                </ul>
              </div>
              <ul class="list-content odd-even">
                <li class="list-row" :key="order.id" v-for="order in modal.create.list.orders">
                  <div class="list-col" style="display: flex; flex-direction: row; align-items: center;">
                    <div class="label">
                      <div class="v-wrapper checkbox" style="min-height: 0px;">
                        <label class="v-checkbox-label" style="min-height: 0px;">
                          <input class="v-checkbox" type="checkbox" :id="order.id" :name="order.id" :checked="order.checked" v-model="order.checked" @change="ToggleOrder">
                          <span class="v-checkbox-toggle" style="width: 18px; height: 18px;" />
                        </label>
                      </div>
                    </div>
                    <span style="margin-left: 10px;"  v-html="Hyperlink({
                      href: ParcelOrder(order.id), target: '_blank', text: order.company_id + ':' + order.increment_id
                    })" />
                  </div>
                  <div class="list-col">{{ order.company_name }}</div>
                  <div class="list-col trackingnumber">
                    <div class="shipping">
                      <span :class="['courier-icon', order.agent_code]" :title="order.agent_name" />
                      <span class="shipping-method">{{ order.shipping_description }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-content">
          <div class="entries">{{ NumberFormat(modal.create.list.selected) }} / {{ NumberFormat(modal.create.list.entries) }}</div>
          <div class="input name" style="width: 100%; max-width: 500px;">
            <label>
              <span>{{ $t('List name') }}</span>
              <input class="input" type="text" :value="modal.create.list.name" @blur="SetListName" @keydown.enter="$event.target.blur()" style="width: 100%;">
            </label>
          </div>
          <button @click="CreateListSubmit" class="button submit done">{{ $t('Create') }}</button>
        </div>
      </div>
      <div class="loading" v-if="modal.create.loading">
        <div class="loading-element">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </Modal>

    <Modal modal="view" :value="modal.view.open" :title="$t('Collect list')">
      <div id="view-list" class="view-list">
        <div class="information">
          <span class="info-primary">
            <p class="info-row">{{ $t('List ID') }}: <span>{{ modal.view.list.id }}</span></p>
            <p class="info-row">{{ $t('Created') }}: <span>{{ DateFormat(modal.view.list.create_date) }}</span></p>
            <p class="info-row">{{ $t('Created by') }}: <span>{{ modal.view.list.create_by }}</span></p>
          </span>
          <span class="info-secondary">
            <p class="info-row">{{ $t('Orders') }}: <span>{{ NumberFormat(modal.view.list.item_count) }}</span></p>
            <p class="info-row">{{ $t('List name') }}: <span>{{ modal.view.list.list_name }}</span></p>
          </span>
        </div>
        <div class="list-view">
          <div class="list-wrapper">
            <div class="list-head">
              <ul class="list-label">
                <li class="list-row">
                  <div class="list-col">{{ $t('List ID') }}</div>
                  <div class="list-col">{{ $t('Company') }}</div>
                  <div class="list-col">{{ $t('Shipping method') }}</div>
                </li>
              </ul>
            </div>
            <div class="list-body">
              <ul class="list-content">
                <li class="list-row" :key="item.id" v-for="item in modal.view.list.items">
                  <div class="list-col">{{ item.company_id }}:{{ item.increment_id }}</div>
                  <div class="list-col">{{ GetCompanyById(item.company_id) }}</div>
                  <div class="list-col trackingnumber">
                    <div class="shipping">
                      <span :class="['courier-icon', item.agent]" :title="(modal.create.couriers.find(option => option.code == item.agent) || {}).label" />
                      <span class="shipping-method">{{ item.shipping }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'ListsCollectLists',
    mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
    components: {
      Modal
    },
    data() {
      return {
        loading: false,
        entries: 0,
        clipboard: null,
        cached: {},
        lists: [],
        list: {},
        page: {
          current: 1,
          number: 1,
          last: 1,
          order: {
            direction: 'desc',
            by: 'id'
          }
        },
        search: {
          query: '',
        },
        shipping: {},
        modal: {
          create: {
            open: false,
            loading: false,
            couriers: [],
            companies: {},
            company: {
              default: '',
              selected: '',
              options: []
            },
            available: {},
            search: {
              query: '',
              company: '',
              courier: '',
              all: ''
            },
            list: {
              name: '',
              orders: [],
              entries: 0,
              selected: 0,
              all: ''
            }
          },
          view: {
            open: false,
            loading: false,
            companies: {},
            list: {}
          }
        }
      }
    },
    created() {},
    async mounted() {
      this.clipboard = await Tool.PasteFromClipboard(true);

      let couriers = BPA.api.Couriers('GET');
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
      });

      BPA.api.Companies('GET').forEach(company => {
        this.modal.create.companies[company.code] = company.name;
        this.modal.view.companies[company.id] = company.name;
        if (company.code == BPA.util.GetCompany()) {
          let selection = {code: company.code, label: company.name};
          this.modal.create.company.default = selection;
          //this.modal.create.company.selected = selection;
        }
        this.modal.create.company.options.push({
          code: company.code,
          label: company.name
        });
      });

      for (let code in couriers) {
        this.modal.create.couriers.push({code: code, label: couriers[code]});
      }
      this.modal.create.couriers.sort((a, b) => {
        a = a.label.toUpperCase(); 
        b = b.label.toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
      
      if (this.clipboard) {
        this.search.query = this.clipboard.replace(/collect\//, '');
      } else {
        this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
      }

      this.QueryLists();
    },
    computed: {
      listNameInput() {
        return document.querySelector('#create-list .footer-content .name input');
      }
    },
    methods: {
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Empty(element) {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      CountryName(country_code) {
        return Tool.CountryName(country_code);
      },
      LanguageName(country_code) {
        return Tool.LanguageName(country_code);
      },
      Webshop(company_id, increment_id) {
        return Tool.Webshop(company_id, increment_id);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      ParcelOrder(order_id = '') {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      Search(event) {
        let input = event.target;
        let value = input.value.replace(/\s+/g, ' ').trim();
        this.search.query = value;
        this.page.current = 1;
        input.value = value;
        this.QueryLists();
        input.blur();
      },
      async QueryLists() {
        this.loading = true;
        await BPA.api.GetShipmentLists({
          query: this.search.query || '',
          order_by: this.page.order.by,
          page_offset: this.page.current,
          order_direction: this.page.order.direction,
        }).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result;
          if (!list.page_offset) list.page_offset = 0;
          this.page.current = list.page_offset + 1;
          this.page.last = list.total_pages || 1;
          this.entries = list.items_total;
          this.lists = list.items;
          if (!this.clipboard) {
            BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
          }
          this.$nextTick().then(() => {
            this.SetPageJumpWidth();
          });
        }).catch(e => e);
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.QueryLists();
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.QueryLists();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      CreateListShow() {
        this.modal.create.company.selected = ''/* this.modal.create.company.default*/;
        this.modal.create.search = {
          query: '',
          company: '' /*this.GetCompanyCode(this.modal.create.company.selected.label)*/,
          courier: '',
          all: ''
        }
        this.modal.create.list = {
          name: '',
          orders: [],
          selected: 0,
          entries: 0,
          all: ''
        }
        this.GetOrders();
        this.modal.create.open = true;
      },
      SearchOrders(event) {
        if (event) {
          if (event.target) {
            if (event.target.localName == 'input') {
              if (event.target.name == 'query') {
                //console.log(event.target.value)
              }
              if (event.target.type == 'checkbox') {
                if (this.modal.create.company.selected) {
                  this.modal.create.search.company = this.GetCompanyCode(this.modal.create.company.selected.label);
                } else {
                  this.modal.create.company.selected = '';
                }                            
                this.modal.create.search.courier = '';
                document.querySelectorAll('.courier input[type=checkbox]').forEach(checkbox => {
                  if (this.modal.create.couriers.find(courier => courier.code == checkbox.name)) {
                    if (event.target != checkbox) {
                      checkbox.checked = false;
                    }
                    if (checkbox.checked) {
                      this.modal.create.search.courier += `${checkbox.name},`;
                    }
                  }
                });
                this.modal.create.search.courier = this.modal.create.search.courier.slice(0, -1);
                if (!this.modal.create.available[this.modal.create.search.courier]) {
                  /*
                  this.modal.create.list.orders = [];
                  this.modal.create.list.selected = 0;
                  this.modal.create.list.entries = 0;
                  this.modal.create.list.all = '';
                  return;
                  */
                }
              }
            }
          } else {
            this.modal.create.search.company = event.code;
          }
        } else {
          this.modal.create.search.company = '';
          this.modal.create.list.selected = 0;
          this.modal.create.list.entries = 0;
          this.modal.create.list.all = '';
        }
        this.GetOrders();
      },
      async GetOrders() {
        const reset_order_list = () => {
          this.modal.create.list.orders = [];
          this.modal.create.list.selected = 0;
          this.modal.create.list.entries = 0;
          this.modal.create.list.all = '';
        }
        for (let prop in this.modal.create.search) {
          const value = this.modal.create.search[prop];
          const type = typeof value;
          if (prop == 'company') this.UpdateCourierLabels(value);
          if (!/query|all/.test(prop) && type == 'string' && !value.length) {
            return reset_order_list();
          }
        }
        this.modal.create.loading = true;
        delete this.modal.create.search.query;
        delete this.modal.create.search.all;
        this.modal.create.list.orders = [];
        const params = Object.values(this.modal.create.search);
        await BPA.api.GetShipmentListOrders(params).then(response => {
          return BPA.api.response({response, return: 'json', 
            error: (message) => {
              reset_order_list();
              if (response && response.status == 400) return response;
              this.$eventHub.$emit('ShowMessages', {
                message: message,
                type: 'error',
                hide: 2000
              });
            }
          });
        }).then(async response => {
          if (!response.ok) {
            return this.modal.create.loading = false;
          }
          let items = response.result || [];
          let orders = [];
          let companies = {};
          let couriers = this.modal.create.couriers;
          BPA.api.Companies('GET').map(company => {
            companies[company.id] = company.name;
          });
          this.modal.create.list.selected = items.length;
          this.modal.create.list.entries = items.length;
          this.modal.create.list.all = items.length ? 'checked' : '';
          items.forEach(order => {
            orders.push({
              ...{
                company_name: companies[order.company_id],
                checked: 'checked'
              }, 
              ...order
            }); 
          });
          await this.GetShippingMethods(params[0]);
          for (let order of orders) {
            order.agent_code = this.shipping[order.shipping_description];
            let option = couriers.find(o => o.code == order.agent_code);
            order.agent_name = option ? option.label : '';
          }
          this.modal.create.list.orders = orders;
          this.listNameInput.dispatchEvent(new Event('blur'));
          this.$nextTick().then(() => {
            this.modal.create.loading = false;
          });
        }).catch(e => e);
      },
      GetCompanyCode(company_name) {
        return Object.keys(this.modal.create.companies).find(key => this.modal.create.companies[key] == company_name);
      },
      async GetShippingMethods(company_code = BPA.util.GetCompany()) {
        if (Object.keys(this.shipping).length) return this.shipping;
        return await BPA.api.GetShippingOptions(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) {
            this.shipping = {};
            return [];
          }
          let shipping = {};
          let options = response.result || [];
          for (let option of options) {
            shipping[option.shipping_method] = option.agent_code;
          }
          this.shipping = shipping;
          return options;
        }).catch(e => e);
      },
      async UpdateCourierLabels(company_code) {
        if (!company_code) return;
        return await BPA.api.GetShipmentListCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          let available = this.modal.create.available;
          if (!Object.keys(json).length) {
            for (let agent in available) {
              json[agent] = available[agent];
            }
          }
          this.modal.create.available = json;
        }).catch(e => e);
      },
      ToggleAllCouriers() {

      },
      ToggleAllOrders(e) {
        this.modal.create.list.selected = Array.from(document.querySelectorAll('#create-list .list-content input[type=checkbox]')).filter(checkbox => checkbox.checked = e.target.checked).length;
      },
      ToggleOrder() {
        this.modal.create.list.selected = document.querySelectorAll('#create-list .list-content input[type=checkbox]:checked').length;
        this.modal.create.list.all = this.modal.create.list.selected == this.modal.create.list.entries;
      },
      SetListName(event) {
        //var value = event.target.value.replace(/\s\s+/g, '').trim();
        var value = event.target.value;
        var company = this.modal.create.search.company;
        var courier = this.modal.create.search.courier;
        var initial = company + ' ' + courier + ' collect';
        if (!company || !courier) return;
        if (!value.length) {
          value = initial;
        } else {
          var comparison = '';
          var val = value.split(' ');
          for (var i = 0; i < val.length; i++) {
            if (Object.keys(this.modal.create.companies).includes(val[0])) {
              comparison = val[0];
              if (this.modal.create.couriers.filter(courier => courier.code == val[1])[0]) {
                comparison += ' '+ val[1];
                if (val[2] == 'collect') {
                  comparison += ' collect';
                  break;
                }
              }
            }
          }
          if (value == comparison) {
            value = initial;
          }
        }
        this.modal.create.list.name = value;
        event.target.value = value;
      },
      AutoCreateListForEachCourier() {
        const lists = {total: 0, created: 0, error: false};
        const company_code = this.modal.create.company.selected.code;
        const printer_id = (BPA.printer.fetch('a4') || {}).id;
        if (!this.modal.create.company.selected) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'No company selected',
            type: 'error',
            hide: 2000
          });
        }
        if (!printer_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'A4 printer is not selected',
            type: 'error',
            hide: 2000
          });
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create lists',
          disapprove: 'No',
          message: 'Creates a collect list for each courier.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.GetShipmentListCount(company_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) {
              return this.loading = false;
            }
            let count = response.result || {};
            let couriers = Object.keys(count).filter(courier => count[courier]);
            if (!couriers.length) {
              this.loading = false;
              return this.$eventHub.$emit('ShowMessages', {
                message: 'No orders available',
                type: 'error',
                hide: 2000
              });
            }
            lists.total = couriers.length;
            couriers.forEach(courier_code => {
              if (lists.error) {
                return this.loading = false;
              }
              let list_name = 'bulk ' + company_code + ' ' + courier_code + ' collect';
              BPA.api.GetShipmentListOrders({
                company_code: company_code, 
                agent_code: courier_code
              }).then(response => {
                return BPA.api.response({response, return: 'json'});
              }).then(response => {
                if (!response.ok || !response.result || lists.error) {
                  return this.loading = false;
                }
                let orders = response.result || [];
                let create = {list_name, items: []};
                orders.forEach(order => create.items.push(order.id));
                BPA.api.CreateShipmentList(create).then(response => {
                  return BPA.api.response({response, return: 'json'});
                }).then(response => {
                  if (!response.ok || !response.result || lists.error) {
                    return this.loading = false;
                  }
                  let json = response.result || {};
                  BPA.api.PrintShipmentList({
                    list_id: json.list_id,
                    md5_hash: json.md5_hash,
                    printer_id: printer_id
                  }).then(response => {
                    return BPA.api.response({response});
                  }).then(response => {
                    if (!response.ok) return;
                    lists.created++;
                    if (lists.created != lists.total) return;
                    this.loading = false;
                    this.$eventHub.$emit('ShowMessages', {
                      message: 'All collect lists successfully created and sent to A4 printer', 
                      type: 'success', 
                      hide: 2000
                    });
                    this.QueryLists();
                    this.$eventHub.$emit('CloseModal', 'create');
                  }).catch(() => {this.loading = false; lists.error = true});
                }).catch(() => {this.loading = false; lists.error = true});
              }).catch(() => {this.loading = false; lists.error = true});
            });
          }).catch(() => this.loading = false);
        });
      },
      async CreateListSubmit() {        
        const create = {name: '', order_ids: []};
        const printer_id = (BPA.printer.fetch('a4') || {}).id;
        const name = document.querySelector('#create-list .input.name input[type=text]');
        const orders = document.querySelectorAll('#create-list .list-content input[type=checkbox]:checked');
        if (!printer_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'A4 printer is not selected',
            type: 'error',
            hide: 2000
          });
        }
        if (!orders.length) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'No orders selected',
            type: 'error',
            hide: 2000
          });
        }
         this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create list',
          disapprove: 'No',
          message: 'Creates collect list for selected orders.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          let default_name = this.modal.create.search.company + ' ' + this.modal.create.search.courier + ' collect';
          create.name = name.value.trim().length ? name.value : default_name;
          orders.forEach((order, i) => create.order_ids[i] = Number(order.id));
          this.modal.create.loading = true;
          BPA.api.CreateShipmentList(create).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) {
              return this.modal.create.loading = false;
            }
            let json = response.result || {};
            BPA.api.PrintShipmentList({
              list_id: json.list_id,
              md5_hash: json.md5_hash,
              printer_id: printer_id
            }).then(response => {
              return BPA.api.response({response});
            }).then(response => {
              this.modal.create.loading = false;
              if (!response.ok) return;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Collect list successfully created and sent to A4 printer',
                type: 'success',
                hide: 2000
              });
              this.QueryLists();
              this.$eventHub.$emit('CloseModal', 'create');
            }).catch(e => e);
          }).catch(() => this.modal.create.loading = false);
        });
      },
      GetCompanyById(id) {
        return this.modal.view.companies[id];
      },
      async PrintPDF(list_id) {
        const printer_id = (BPA.printer.fetch('a4') || {}).id;
        if (!printer_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'A4 printer is not selected',
            type: 'error',
            hide: 2000
          });
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, print list',
          disapprove: 'No',
          message: 'Reprints the chosen list.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.PrintShipmentList({
            list_id: list_id,
            printer_id: printer_id
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            this.$eventHub.$emit('ShowMessages', {
              message: 'Collect list sent to A4 printer', 
              type: 'success', 
              hide: 2000
            });
          }).catch(e => e);
        });
      },
      async OpenPDF(list_id) {
        let blob = await this.GetPdfAsBlob(list_id);
        let blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        URL.revokeObjectURL(blobURL);
      },
      async DownloadPDF(list) {
        let blob = await this.GetPdfAsBlob(list.id);
        let date = Tool.DateToISO(list.create_date, true);
        let create_date = date.split(' ').map(d => d.split('-').reverse().join('-').replace(/:/g, '_')).join(' ');
        if (blob) BPA.api.download({
          blob: blob, 
          name: `${list.name} ${create_date}`, 
          new_tab: BPA.browser == 'firefox'
        });
      },
      async GetPdfAsBlob(list_id) {
        return await new Promise((resolve, reject) => {
          if (!list_id) return reject();
          this.loading = true;
          BPA.api.DownloadShipmentList(list_id).then(response => {
            return BPA.api.response({response, 
              return: () => {
                return response.arrayBuffer();
              }
            });
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            let arrayBuffer = response.result || {};
            resolve(new Blob([arrayBuffer], {type: 'application/pdf'}));
          }).catch(reject);
        }).catch(e => e);
      },
      SetOrderBy(value) {
        if (this.page.order.by == value) {
          if (this.page.order.direction == 'desc') {
            this.page.order.direction = 'asc';
          } else {
            this.page.order.direction = 'desc';
          }
        }
        this.page.order.by = value;
        this.QueryLists();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    cursor: pointer;
    
    &:hover {
      .v-checkbox-toggle {
        border-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
</style>